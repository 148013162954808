import React, { useState, useEffect } from "react";
import TeploControlWorker from "./TeploControlWorker";
import OpenShift from "./OpenShift";
import { useCheckShiftExists } from "../utils/useCheckSmena";

function StartPage() {
  const [isShiftOpen, setIsShiftOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { checkShiftExists } = useCheckShiftExists();

  useEffect(() => {
    const fetchShiftStatus = async () => {
      setLoading(true);
      const shiftOpen = await checkShiftExists();
      setIsShiftOpen(shiftOpen);
      setLoading(false);
    };
  
    fetchShiftStatus();
  }, [checkShiftExists]); 
  

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return isShiftOpen ? <TeploControlWorker /> : <OpenShift />;
}

export default StartPage;
