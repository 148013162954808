import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from 'react';
import v3 from '../images/iznos.mp4'


const WearPage = () => {


    const [IsV, setIsV] = useState(false);


    function handleButtonClick() {
        setIsV(!IsV);
    }


    return (
        <>
            <Header />
            <div className="ForWear">
                <div className="container">
                    <div className="ForWear__title">
                        Программа «МКД износ»
                    </div>
                    <div className="ForWear__inf">
                        Разработана Инжиниринговым центром «Технологии цифровой среды» Федерального государственного бюджетного образовательного учреждения высшего образования «Орловский государственный университет имени И.С. Тургенева».<br /><br />
                    </div>
                </div>
                <div className="ForWear__block2">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            <p style={{ marginBottom: '20px' }}>
                                Программа позволяет оценить и визуализировать степень износа гражданских зданий, включая многоквартирные дома.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                При расчете физического износа здания не применяются механизмы расчета по восстановительной стоимости его элементов и объекта в целом, а также по нормативному сроку службы и возрасту зданий.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                В основе программного обеспечения принят Метод итераций, объединяющий экспертный метод и автоматизированную информационную среду.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                При использовании метода итераций периодичность проведения обязательной экспертной оценки технического состояния здания – два раза в год в периоды сезонных обходов.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                При экспертном обследовании конкретного элемента здания сравниваются фактические повреждения, выявленные в результате визуального и инструментального обследования, с табличными характеристиками технического состояния, которые фиксируются в соответствующей ячейке диапазона износа.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                На основании расчетных данных физического износа и удельного веса элементов строительных конструкций, его инженерных систем рассчитывается физический износ жилого всего здания.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                Пользователи программного сервиса — организации, осуществляющие управление многоквартирными домами.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2 ForWear__forMargBot">
                    <div className="container">
                        <div className="ForWear__ForMargin">
                            <button className="ForVzy__btn ForAds__ForVBtn" onClick={handleButtonClick}>
                                Демо видео
                            </button>
                        </div>
                        {IsV === true ? (
                            <div className="ForAds__BlocV">
                                <video className="AdsPage__V2" width="1200" height="660" controls>
                                    <source src={v3} type="video/mp4" />
                                </video>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WearPage;