import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getJobTitle } from "./http/http";
import AuthContext from "../../context/AuthContext";
import Layout from "../../components/Layout";
import StartPage from "./components/StartPage";

export const TeploApp = () => {
  let navigate = useNavigate();

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [jobTitle, setJobTitle] = useState(); //Должность

  useEffect(() => {
    getJobTitle(authTokens).then((data) => {
      if (!data?.[0]?.TControl_access) {
        navigate("/", { replace: true });
      }
      if (data?.[0]?.job_title) {
        setJobTitle(data[0].job_title);
      } else if (data?.detail === "Учетные данные не были предоставлены.") {
        logoutUser();
      } else {
        localStorage.setItem("last_page", "/services/teplocontrol");
        navigate("/", { replace: true });
      }
    });
  }, []);

  return (
    <Layout>
      {jobTitle ? (
        jobTitle === "Работник" && 
        <StartPage/>
      ) : (
        <p>Загрузка...</p>
      )}
    </Layout>
  );
};

export default TeploApp;
