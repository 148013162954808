import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../../../config"; // Убедитесь, что путь корректный
import AuthContext from "../../../../context/AuthContext";
import styles from "./styles/KotelnyeDetails.module.scss";

function KotelnyeDetails() {
  const { authTokens } = useContext(AuthContext);
  const [kotelnye, setKotelnye] = useState([]);
  const [selectedKotelnaya, setSelectedKotelnaya] = useState(null);

  // Функция для загрузки данных котельных
  const fetchKotelnye = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();
      const transformedData = data.map((item) => ({
        name: item.address,
        hasSchema: item.newtwork_schema !== null,
        schemaUrl: item.newtwork_schema,
      }));
      setKotelnye(transformedData);
    } catch (error) {
      console.error("Ошибка при загрузке котельных:", error);
    }
  };

  useEffect(() => {
    fetchKotelnye();
  }, []);

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const kotelnaya = kotelnye.find((k) => k.name === selectedName);
    setSelectedKotelnaya(kotelnaya);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Выбор котельной</h3>
      <p className={styles.description}>
        Пожалуйста, выберите нужную котельную для просмотра информации.
      </p>
      <div className={styles.selectWrapper}>
        <select
          onChange={handleSelectChange}
          className={styles.select}
          defaultValue=""
        >
          <option value="" disabled>
            Выберите из списка
          </option>
          {kotelnye.map((k, index) => (
            <option key={index} value={k.name}>
              {k.name}
            </option>
          ))}
        </select>
        <span className={styles.arrow}></span>
      </div>

      {selectedKotelnaya && (
        <div className={styles.info}>
          {selectedKotelnaya.hasSchema ? (
            <button
              className={styles.button}
              onClick={() => window.open(selectedKotelnaya.schemaUrl, "_blank")}
            >
              Просмотреть схему
            </button>
          ) : (
            <p className={styles.message}>
              Схема для выбранной котельной пока недоступна.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default KotelnyeDetails;
