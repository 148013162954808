import React, { useState, useEffect, useContext } from "react";
import useCurrentShift from "../../utils/useCurrentShift";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";
import styles from "./styles/OperativeInputs.module.scss";

function OperativeInputs() {
  const { authTokens } = useContext(AuthContext);
  const { currentShift } = useCurrentShift();
  const [boilerRooms, setBoilerRooms] = useState([]);
  const [selectedBoilerRoom, setSelectedBoilerRoom] = useState(null);
  const [inputValues, setInputValues] = useState({
    teplonositelPodachi: {
      temp: "",
      pressure: "",
      returnTemp: "",
      returnPressure: "",
    },
    gvsPodachi: { temp: "", pressure: "", returnTemp: "", returnPressure: "" },
    ostalnoe: { waterPressure: "", gasPressure: "" },
  });

  useEffect(() => {
    const fetchBoilerRooms = async () => {
      try {
        const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        setBoilerRooms(data);
      } catch (error) {
        console.error("Ошибка при загрузке котельных:", error);
      }
    };
    fetchBoilerRooms();
  }, []);

  const handleChange = (section, field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleAddParameters = async () => {
    try {
      const requestBody = {
        smena_id: currentShift.pk,
        boilerhouse_id: selectedBoilerRoom?.pk || 0,
        t1: inputValues.teplonositelPodachi.temp || 0,
        t2: inputValues.teplonositelPodachi.returnTemp || 0,
        p1: inputValues.teplonositelPodachi.pressure || 0,
        p2: inputValues.teplonositelPodachi.returnPressure || 0,
        pressure: inputValues.ostalnoe.waterPressure || 0,
        gas: inputValues.ostalnoe.gasPressure || 0,
        note: "0",
      };

      if (selectedBoilerRoom?.gvs_activity) {
        requestBody.t3 = inputValues.gvsPodachi.temp || 0;
        requestBody.t4 = inputValues.gvsPodachi.returnTemp || 0;
        requestBody.p3 = inputValues.gvsPodachi.pressure || 0;
        requestBody.p4 = inputValues.gvsPodachi.returnPressure || 0;
      }

      const response = await fetch(`${API_URL}/TControl/dynamic_settings/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Параметры добавлены успешно !");
      } else {
        console.error("Ошибка при отправке запроса:", response.statusText);
      }
    } catch (error) {
      console.error("Ошибка при отправке запроса:", error);
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.dropdownGroup}>
        <label htmlFor="boilerRoom">Выберите котельную</label>
        <select
          className={styles.dropdown}
          id="boilerRoom"
          value={selectedBoilerRoom?.pk || ""}
          onChange={(e) => {
            const selected = boilerRooms.find(
              (room) => room.pk === parseInt(e.target.value)
            );
            setSelectedBoilerRoom(selected || null);
          }}
        >
          <option value="">Выберите...</option>
          {boilerRooms.map((room) => (
            <option key={room.pk} value={room.pk}>
              {room.address}
            </option>
          ))}
        </select>
      </div>

      {selectedBoilerRoom && (
        <div className={styles.sectionGroupContainer}>
          {selectedBoilerRoom.coolant_activity && (
            <div className={styles.sectionGroup}>
              {/* Теплоноситель */}
              <div className={styles.section}>
                <h3>Теплоноситель</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="temp-teplonositel">Температура подачи:</label>
                  <input
                    id="temp-teplonositel"
                    type="number"
                    placeholder="T1"
                    value={inputValues.teplonositelPodachi.temp}
                    onChange={(e) =>
                      handleChange(
                        "teplonositelPodachi",
                        "temp",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="pressure-teplonositel">
                    Давление подачи:
                  </label>
                  <input
                    id="pressure-teplonositel"
                    type="number"
                    placeholder="P1"
                    value={inputValues.teplonositelPodachi.pressure}
                    onChange={(e) =>
                      handleChange(
                        "teplonositelPodachi",
                        "pressure",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="returnTemp-teplonositel">
                    Обратная температура:
                  </label>
                  <input
                    id="returnTemp-teplonositel"
                    type="number"
                    placeholder="T2"
                    value={inputValues.teplonositelPodachi.returnTemp}
                    onChange={(e) =>
                      handleChange(
                        "teplonositelPodachi",
                        "returnTemp",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="returnPressure-teplonositel">
                    Обратное давление:
                  </label>
                  <input
                    id="returnPressure-teplonositel"
                    type="number"
                    placeholder="P2"
                    value={inputValues.teplonositelPodachi.returnPressure}
                    onChange={(e) =>
                      handleChange(
                        "teplonositelPodachi",
                        "returnPressure",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {selectedBoilerRoom.gvs_activity && (
            <div className={styles.sectionGroup}>
              {/* ГВС Section */}
              <div className={styles.section}>
                <h3>ГВС</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="temp-gvs">Температура подачи:</label>
                  <input
                    id="temp-gvs"
                    type="number"
                    placeholder="T3"
                    value={inputValues.gvsPodachi.temp}
                    onChange={(e) =>
                      handleChange("gvsPodachi", "temp", e.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="pressure-gvs">Давление подачи:</label>
                  <input
                    id="pressure-gvs"
                    type="number"
                    placeholder="P3"
                    value={inputValues.gvsPodachi.pressure}
                    onChange={(e) =>
                      handleChange("gvsPodachi", "pressure", e.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="returnTemp-gvs">Обратная температура:</label>
                  <input
                    id="returnTemp-gvs"
                    type="number"
                    placeholder="T4"
                    value={inputValues.gvsPodachi.returnTemp}
                    onChange={(e) =>
                      handleChange("gvsPodachi", "returnTemp", e.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="returnPressure-gvs">Обратное давление:</label>
                  <input
                    id="returnPressure-gvs"
                    type="number"
                    placeholder="P4"
                    value={inputValues.gvsPodachi.returnPressure}
                    onChange={(e) =>
                      handleChange(
                        "gvsPodachi",
                        "returnPressure",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {selectedBoilerRoom && (
            <div className={styles.sectionGroup}>
              {/* Остальные параметры Section */}
              <div className={styles.section}>
                <h3>Остальное</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="waterPressure-ostalnoe">Давление воды:</label>
                  <input
                    id="waterPressure-ostalnoe"
                    type="number"
                    placeholder="Pвх"
                    value={inputValues.ostalnoe.waterPressure}
                    onChange={(e) =>
                      handleChange("ostalnoe", "waterPressure", e.target.value)
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="gasPressure-ostalnoe">Давление газа:</label>
                  <input
                    id="gasPressure-ostalnoe"
                    type="number"
                    placeholder="Pгаз"
                    value={inputValues.ostalnoe.gasPressure}
                    onChange={(e) =>
                      handleChange("ostalnoe", "gasPressure", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedBoilerRoom && (
        <div className={styles.formGroup}>
          <button onClick={handleAddParameters} className={styles.addButton}>
            Добавить параметры
          </button>
        </div>
      )}
    </div>
  );
}

export default OperativeInputs;
