import { useState, useEffect, useContext } from "react";
import { API_URL } from "../../../config";
import AuthContext from "../../../context/AuthContext";

const useCurrentShift = () => {
  const { authTokens } = useContext(AuthContext);
  const [currentShift, setCurrentShift] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Функция для получения текущей смены
  const fetchCurrentShift = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/TControl/smena/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении текущей смены");
      }

      const shifts = await response.json();
      const openShift = shifts.find((shift) => !shift.flag_complete);

      setCurrentShift(openShift || null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentShift();
  }, []); 

  return { currentShift, loading, error, refetch: fetchCurrentShift };
};

export default useCurrentShift;
