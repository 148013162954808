import { useContext, useCallback } from "react";
import { API_URL } from "../../../config";
import AuthContext from "../../../context/AuthContext";

export const useCheckShiftExists = () => {
  const { authTokens } = useContext(AuthContext);

  const checkShiftExists = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/smena/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (response.ok) {
        const shifts = await response.json();
        const shiftExists = shifts.some((shift) => !shift.flag_complete);
        return shiftExists;
      } else {
        console.error("Ошибка при получении списка смен");
        return false;
      }
    } catch (error) {
      console.error("Ошибка:", error);
      return false;
    }
  }, [authTokens]);

  return { checkShiftExists };
};
