import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from "../images/logo.svg";
import { API_URL } from "../config";

const Header = () => {
    let { user, logoutUser, authTokens } = useContext(AuthContext);

    let [profile, setProfile] = useState({
        fare_access: false,
        wear_access: false,
        RSO_access: false,
        ADS_access: false,
        ADS_heat_access: false,
        TControl_access: false
    });

    useEffect(() => {
        getProfile();
    }, []);

    let getProfile = async () => {
        let response = await fetch(API_URL + '/user/profile/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + String(authTokens?.access),
            },
        });
        let data = await response.json();

        if (response.status === 200) {
            setProfile(data[0]);
        }
    };

    const [isDroppedMenu, setIsDroppedMenu] = useState(false);
    const [isMenuActive, isMenuActiveSet] = useState(false);

    const menuActive = () => {
        isMenuActiveSet(!isMenuActive);
    };

    const droppedMenu = () => {
        setIsDroppedMenu(!isDroppedMenu);
    };

    const authLinks = (
        <>
            <li>
                <div className='menu__list-box'>
                    <div
                        className={
                            'menu__list-container ' +
                            (isDroppedMenu ? 'menu__list-container--active' : '')
                        }
                    >
                        {profile?.fare_access === true ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv1'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/fare'>
                                    Расчет - обоснование платы
                                </NavLink>
                            </div>
                        ) : (
                            ""
                        )}
                        {profile?.wear_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv2'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/wear'>
                                    Расчет износа жилых зданий
                                </NavLink>
                            </div>
                        ) : (
                            ""
                        )}
                        {profile?.wear_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv10'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/wearbuilding'>
                                    Расчет износа гражданских зданий
                                </NavLink>
                            </div>
                        ) : (
                            ""
                        )}
                        {profile?.RSO_access && (profile?.job_title == "Работник" || profile?.job_title == "Технический администратор" || profile?.job_title == "Диспетчер") ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv4'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/rso/dynamic'>
                                    РСО Динамик
                                </NavLink>
                            </div>
                        ) : (
                            ""
                        )}

                        {profile?.RSO_access && (profile?.job_title == "Гидрогиолог" || profile?.job_title == "Начальник гидрогиологов") ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv6'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/rso/hydrologist'>
                                    Гидрогеолог
                                </NavLink>
                            </div>
                        ) : (
                            ""
                        )}

                        {profile?.ADS_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv5'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/ads'>АДС Вода</NavLink>
                            </div>) : (
                            ""
                        )}
                        {profile?.ADS_heat_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv5'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/Tads'>АДС Тепло</NavLink>
                            </div>) : (
                            ""
                        )}
                        {profile?.TControl_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv5'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/tcontrol'>ТЕПЛОконтроль</NavLink>
                            </div>) : (
                            ""
                        )}
                        {profile?.TControl_access ? (
                            <div className='menu__option'>
                                <input
                                    className='menu__input'
                                    type='radio'
                                    id='serv55'
                                    name='services'
                                />
                                <NavLink className='menu__navlink' to='/services/teplocontrol'>Тепло</NavLink>
                            </div>) : (
                            ""
                        )}

                    </div>
                    <div className='menu__selected' onClick={droppedMenu}>
                        Сервисы
                    </div>
                </div>
            </li>
        </>
    );

    const authHeaderLinks = (
        <ul className='header__list'>
            <li className='header__list-item'>
            </li>
            <li className='header__list-item'>
                <NavLink className='header__list-link' to='/' onClick={logoutUser}>
                    Выход
                </NavLink>
            </li>
        </ul>
    );

    const guestLinks = (
        <>
        </>
    );

    const guestHeaderLinks = (
        <ul className='header__list'>
            <li className='header__list-item'>
                <NavLink onClick={() => localStorage.setItem("UserSelect", "Register")} className='header__list-link'
                    to='/login'>
                    Регистрация
                </NavLink>
            </li>
            <li className='header__list-item'>
                <NavLink onClick={() => localStorage.setItem("UserSelect", "Login")} className='header__list-link'
                    to='/login'>
                    Вход
                </NavLink>
            </li>
        </ul>
    );

    return (
        <div className='top'>
            <header className='header'>
                <div className='container'>
                    <div className='header__inner'>
                        <button
                            onClick={menuActive}
                            className={
                                'menu__btn ' + (isMenuActive ? 'menu__btn--active' : '')
                            }
                        >
                            <svg
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    fill='none'
                                    stroke='#fff'
                                    strokeWidth='2'
                                    d='M2,19 L22,19 M2,5 L22,5 M2,12 L22,12'
                                />
                            </svg>
                        </button>

                        <div className='logo__box'>
                            <Link className='logo__link' to='/'>
                                <img src={logo} alt='logo' />
                            </Link>
                        </div>
                        <div className='' />
                        {user ? authHeaderLinks : guestHeaderLinks}
                    </div>
                </div>
            </header>

            <div className={'menu ' + (isMenuActive ? 'menu--active' : '')}>
                <ui className='menu-list'>
                    <li>
                        <NavLink className='menu-list__link' to='/about'>
                            О платформе
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='menu-list__link' to='/news'>
                            Новости
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='menu-list__link' to='/rules'>
                            Авторские права
                        </NavLink>
                    </li>
                    {user ? authLinks : guestLinks}
                </ui>
            </div>
        </div>
    );
};

export default Header;
