import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";
import styles from "./styles/OperativeParameters.module.scss";

const OperativeParameters = () => {
  const { authTokens } = useContext(AuthContext);
  const [selectedBoiler, setSelectedBoiler] = useState("");
  const [boilerRooms, setBoilerRooms] = useState([]);
  const [allBoilerNames, setAllBoilerNames] = useState([]);

  // Загрузка списка котельных
  const fetchBoilerRooms = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();
      setAllBoilerNames(data);
    } catch (error) {
      console.error("Ошибка при загрузке котельных:", error);
    }
  };

  // Загрузка параметров котельных
  const fetchOperativeParameters = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/dynamic_settings/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();
      setBoilerRooms(data);
    } catch (error) {
      console.error("Ошибка при загрузке данных по котельным:", error);
    }
  };

  useEffect(() => {
    fetchOperativeParameters();
    fetchBoilerRooms();
  }, []);

  // Фильтрация и сортировка данных
  const filteredBoilerRooms = boilerRooms
    .filter(
      (boiler) => !selectedBoiler || boiler.boilerhouse_id === Number(selectedBoiler)
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Проверка на утечки
  const boilerRoomsWithLeakage = filteredBoilerRooms.map((boiler, index, array) => {
    if (index === array.length - 1) {
      // Последняя запись в списке: невозможно сравнить с предыдущей
      return { ...boiler, leakageMessage: "Нет" };
    }

    const nextBoiler = array[index + 1]; // Следующая (более старая) запись
    const hasLeakage =
      (nextBoiler.p1 > boiler.p1 * 1.1) ||
      (nextBoiler.p2 > boiler.p2 * 1.1) ||
      (nextBoiler.p3 > boiler.p3 * 1.1) ||
      (nextBoiler.p4 > boiler.p4 * 1.1);

    return {
      ...boiler,
      leakageMessage: hasLeakage ? "Внимание утечка!" : "Нет",
    };
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Параметры котельной</h2>

      {/* Селектор для выбора котельной */}
      <div className={styles.dropdownWrapper}>
        <label htmlFor="boilerSelect" className={styles.label}></label>
        <select
          id="boilerSelect"
          className={styles.dropdown}
          value={selectedBoiler}
          onChange={(e) => setSelectedBoiler(e.target.value)}
        >
          <option value="">Выберите котельную</option>
          {allBoilerNames.map((boiler) => (
            <option key={boiler.pk} value={boiler.pk}>
              {boiler.address}
            </option>
          ))}
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th rowSpan="2">Время/Дата</th>
            <th rowSpan="2">Котельная</th>
            <th colSpan="1">Давление воды на вводе</th>
            <th colSpan="4">Теплоноситель</th>
            <th colSpan="4">ГВС</th>
            <th colSpan="1">Давление газа на вводе</th>
            {/* Условно рендерим столбец "Наличие утечек", если выбрана котельная */}
            {selectedBoiler && <th rowSpan="2">Наличие утечек</th>}
          </tr>
          <tr>
            <th>Рвх</th>
            <th>Т1</th>
            <th>Р1</th>
            <th>Т2</th>
            <th>Р2</th>
            <th>Т3</th>
            <th>Р3</th>
            <th>Т4</th>
            <th>Р4</th>
            <th>Ргаз</th>
          </tr>
        </thead>
        <tbody>
          {boilerRoomsWithLeakage.map((boiler, index) => (
            <tr key={index}>
              <td>
                {new Date(boiler.created_at).toLocaleDateString("ru-RU", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td>{boiler.boilerhouse_id}</td>
              <td>{boiler.pressure}</td>
              <td>{boiler.t1}</td>
              <td>{boiler.p1}</td>
              <td>{boiler.t2}</td>
              <td>{boiler.p2}</td>
              <td>{boiler.t3}</td>
              <td>{boiler.p3}</td>
              <td>{boiler.t4}</td>
              <td>{boiler.p4}</td>
              <td>{boiler.gas}</td>
              {/* Если выбрана котельная, выводим статус утечек */}
              {selectedBoiler && <td>{boiler.leakageMessage}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OperativeParameters;
