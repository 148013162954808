import React, { useState } from 'react';
import KotelnyeDetails from './components/KotelnyeDetails';
import MonthlyIndicators from './components/MonthlyIndicators';
import OperativeParameters from './components/OperativeParameters';
import DayEntries from './components/DayEntries';
import OperativeInputs from './components/OperativeInputs';
import ShiftClosing from './components/ShiftClosing';
import styles from './TeploControlWorker.module.scss';

function TeploControlWorker() {
  const [activeContent, setActiveContent] = useState('OperativeParameters');

  const handleButtonClick = (content) => {
    setActiveContent(content);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('KotelnyeDetails')}>Котельные детально</button>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('MonthlyIndicators')}>Показатели за месяц</button>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('OperativeParameters')}>Оперативные параметры</button>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('DayEntries')}>Ввод дневных показаний</button>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('OperativeInputs')}>Ввод оперативных параметров</button>
        <button className={styles.mainBtn} onClick={() => handleButtonClick('ShiftClosing')}>Закрытие смены</button>
      </div>
      <div className={styles.contentContainer}>
        {activeContent === 'KotelnyeDetails' && <KotelnyeDetails />}
        {activeContent === 'MonthlyIndicators' && <MonthlyIndicators />}
        {activeContent === 'OperativeParameters' && <OperativeParameters />}
        {activeContent === 'DayEntries' && <DayEntries />}
        {activeContent === 'OperativeInputs' && <OperativeInputs />}
        {activeContent === 'ShiftClosing' && <ShiftClosing />}
      </div>
    </div>
  );
}

export default TeploControlWorker;
