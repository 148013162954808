import React, { useState, useEffect, useContext } from "react";
import styles from "./styles/MonthlyIndicators.module.scss";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";

function MonthlyIndicators() {
  const { authTokens } = useContext(AuthContext);
  const [selectedBoiler, setSelectedBoiler] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [data, setData] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [boilerRooms, setBoilerRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBoilerRooms = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка загрузки котельных");
      }

      const result = await response.json();
      setBoilerRooms(result);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${API_URL}/TControl/daily_dynamic_settings/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка загрузки данных");
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSensors = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/sensors/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка загрузки датчиков");
      }

      const result = await response.json();
      setSensors(result);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchBoilerRooms();
    fetchData();
    fetchSensors();

    const currentDate = new Date().toISOString().split("T")[0];
    setSelectedDate(currentDate);
  }, []);

  const calculateConsumptionPerHour = (consumption, operationTime) => {
    if (!consumption || !operationTime) return "-";
    const [hours, minutes, seconds] = operationTime.split(":").map(Number);
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours > 0 ? (consumption / totalHours).toFixed(2) : "-";
  };

  const getSensorDetails = (sensorId) => {
    const sensor = sensors.find((s) => s.id === sensorId);
    return sensor
      ? {
          resource: sensor.sensor_type,
          boilerhouseId: sensor.boilerhouse_id,
          number: sensor.number,
        }
      : {};
  };

  const handleResourceChange = async (e) => {
    const resource = e.target.value;
    setSelectedResource(resource);

    if (resource) {
      setLoading(true);
      setError(null);

      const apiUrl = `${API_URL}/TControl/daily_dynamic_settings/get/${resource}/parameters/${selectedDate}/`;

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Ошибка загрузки данных для ресурса: ${resource}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      fetchData();
    }
  };

  const handleDateChange = async (e) => {
    const date = e.target.value;
    setSelectedDate(date);

    if (selectedResource) {
      setLoading(true);
      setError(null);

      const apiUrl = `${API_URL}/TControl/daily_dynamic_settings/get/${selectedResource}/parameters/${date}/`;

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Ошибка загрузки данных для даты: ${date}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      fetchData();
    }
  };

  const filteredData = data
    .filter(
      (item) =>
        !selectedBoiler ||
        getSensorDetails(item.sensor_id).boilerhouseId === Number(selectedBoiler)
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Месячные показатели</h3>
      <div className={styles.controls}>
        <select
          className={styles.dropdown}
          value={selectedBoiler}
          onChange={(e) => setSelectedBoiler(e.target.value)}
        >
          <option value="">Выберите котельную</option>
          {boilerRooms.map((boiler) => (
            <option key={boiler.pk} value={boiler.pk}>
              {boiler.address}
            </option>
          ))}
        </select>

        <select
          className={styles.dropdown}
          value={selectedResource}
          onChange={handleResourceChange}
        >
          <option value="">Выберите энергоресурс</option>
          <option value="electricity">Электричество</option>
          <option value="water">Вода</option>
          <option value="gas">Газ</option>
        </select>

        <input
          type="date"
          className={styles.dateInput}
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      {loading && <p>Загрузка данных...</p>}
      {error && <p className={styles.error}>Ошибка: {error}</p>}

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Дата измерения</th>
            <th>Показания ПУ</th>
            <th>Время работы ПУ</th>
            <th>Потребление</th>
            <th>Энергоресурс</th>
            <th>Котельная</th>
            <th>Датчик</th>
            <th>Смена</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => {
            const { resource, boilerhouseId, number } = getSensorDetails(
              item.sensor_id
            );
            return (
              <tr key={item.pk}>
                <td>{new Date(item.created_at).toLocaleDateString("ru-RU")}</td>
                <td>{item.consumption}</td>
                <td>{item.operation_time}</td>
                <td>
                  {calculateConsumptionPerHour(
                    item.consumption,
                    item.operation_time
                  )}
                </td>
                <td>{resource || "-"}</td>
                <td>{boilerhouseId || "-"}</td>
                <td>{number || "-"}</td>
                <td>{item.smena_id}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MonthlyIndicators;
